<template>
    <ODropdown placement="bottom-end">
        <template #default="scope">
            <span class="o365-header-cell-menu mx-1" :ref="scope.target" @click="scope.open">
                <i class="bi bi-list"></i>
            </span>
        </template>
        <template #dropdown="scope">
            <div class="card shadow dropdown-menu rounded-0 o365-header-dropdown"
                style="position: absolute; z-index: 10000; min-width: 200px;" :ref="scope.container">
                <template v-if="col.bulkUpdate">
                    <button class="dropdown-item" @click="() => {/*not implemented*/}">
                        {{$t('Bulk Update')}}
                    </button>
                    <div class="dropdown-divider"></div>
                </template>

                <template v-if="!gridControl.props.disablePinning">
                    <o-dropdown placement="right">
                        <template #default="pinScope">
                            <button class="dropdown-item" :ref="pinScope.target"
                                @click="pinScope.open">
                                {{$t('Pin Column')}}
                                <i class="bi bi-caret-right-fill float-end"></i>    
                            </button>
                        </template>
                        <template #dropdown="pinScope">
                            <div :ref="pinScope.container" class="card shadow dropdown-menu rounder-0">
                                <button class="dropdown-item"
                                    @click="()=>{ gridControl.header.handleColumnPin(col, 'left'); scope.close();}">
                                    <i class="bi bi-check2" :style="{'color': col.pinned === 'left' ? null : 'transparent'}"></i>
                                    {{$t('Pin Left')}}
                                </button>
                                <button class="dropdown-item"
                                    @click="()=>{ gridControl.header.handleColumnPin(col, 'right'); scope.close();}">
                                    <i class="bi bi-check2" :style="{'color': col.pinned === 'right' ? null : 'transparent'}"></i>
                                    {{$t('Pin Right')}}
                                </button>
                                <button class="dropdown-item"
                                    @click="()=>{ gridControl.header.handleColumnPin(col); scope.close();}">
                                    <i class="bi bi-check2" :style="{'color': !col.pinned ? null : 'transparent'}"></i>
                                    {{$t('No Pin')}}
                                </button>
                            </div>
                        </template>
                    </o-dropdown>
                    <div class="dropdown-divider"></div>
                </template>

                <template v-if="col.sortable">
                    <button class="dropdown-item"
                        @click="handleHeaderClick($event, col, 'asc'); scope.close()">{{$t('Sort Acending')}}</button>
                    <button class="dropdown-item"
                        @click="handleHeaderClick($event, col, 'desc'); scope.close()">{{$t('Sort Decending')}}</button>
                    <div class="dropdown-divider"></div>
                </template>

                <template v-if="gridControl.groupBy">
                    <button class="dropdown-item"
                        @click="()=>{gridControl.groopByColumn(col); scope.close(); }">{{$t('Group By')}}</button>
                    <div class="dropdown-divider"></div>
                </template>

                <button class="dropdown-item"
                    @click="()=>{col.hide = true; scope.close(); }">{{$t('Hide Column')}}</button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item"
                    @click="gridControl.resetLayout();scope.close();">{{$t('Reset Layout')}}</button>
            </div>
        </template>
    </ODropdown>
</template>

<script lang="ts">
import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';
import { IBaseHeaderCellProps } from './o365.vue.components.Grid.BaseHeaderCell.vue';

export interface IBaseHeaderCellMenuProps {
    col: BaseColumn; 
};
</script>

<script setup lang="ts">
import ODropdown from 'o365.vue.components.DropDown.vue'; 
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';

const props = defineProps<IBaseHeaderCellMenuProps>();

const gridControl = useBaseGridControl();
</script>